<template>
  <div class="command-center">
    <div class="mapContainer" id="mapContainer"></div>
    <div class="optionDiv">
      <div class="btn_gis">
        <el-button type="info" @click="clearMap(true)">清空网格</el-button>
        <!--            <el-button type="primary" @click="getOverlays">图层数</el-button>-->
        <el-button type="primary" @click="openPolygonTool">绘制网格</el-button>
        <!--        <el-row>-->
        <!--          <el-col :span="16">-->
        <!--&lt;!&ndash;            <el-button type="primary" @click="openPolylineTool">线工具</el-button>&ndash;&gt;-->
        <!--&lt;!&ndash;            <el-button type="primary" @click="openRectangleTool">矩形工具</el-button>&ndash;&gt;-->
        <!--&lt;!&ndash;            <el-button type="primary" @click="openCircleTool">圆圈工具</el-button>&ndash;&gt;-->
        <!--&lt;!&ndash;            <el-button type="primary" @click="handlerPaint">画笔</el-button>&ndash;&gt;-->
        <!--&lt;!&ndash;            <el-button type="primary" @click="handler.clear()">清空画布</el-button>&ndash;&gt;-->
        <!--&lt;!&ndash;            <el-button type="primary" @click="addMapClick(0)">标注</el-button>&ndash;&gt;-->
        <!--&lt;!&ndash;            <el-button type="primary" @click="removeMapClick">取消标注</el-button>&ndash;&gt;-->
        <!--          </el-col>-->
        <!--        </el-row>-->
        <!--        <el-row>-->
        <!--          <el-col :span="16">-->
        <!--            <el-row>-->
        <!--              <el-select-->
        <!--                  v-model="startLocalKey"-->
        <!--                  :multiple="false"-->
        <!--                  :reserve-keyword="false"-->
        <!--                  filterable-->
        <!--                  remote-->
        <!--                  placeholder="请输入起始点"-->
        <!--                  :remote-method="querySearchSatrt"-->
        <!--                  @change="selectOne" clearable-->
        <!--                  :loading="loading">-->
        <!--                <el-option-->
        <!--                    v-for="(item, index) in startSearchArr"-->
        <!--                    :key="index"-->
        <!--                    :label="item.name +' - '+ item.address"-->
        <!--                    :value="item">-->
        <!--                </el-option>-->
        <!--              </el-select>-->
        <!--              <el-button type="primary" @click="addMapClick(1)">标注起始点</el-button>-->
        <!--            </el-row>-->
        <!--            <el-row>-->
        <!--              <el-select-->
        <!--                  v-model="endLocalKey"-->
        <!--                  :multiple="false"-->
        <!--                  :reserve-keyword="false"-->
        <!--                  filterable-->
        <!--                  remote-->
        <!--                  placeholder="请输入目的地"-->
        <!--                  :remote-method="querySearchEnd"-->
        <!--                  @change="selectOne" clearable-->
        <!--                  :loading="loading">-->
        <!--                <el-option-->
        <!--                    v-for="(item, index) in endSearchArr"-->
        <!--                    :key="index"-->
        <!--                    :label="item.name +' - '+ item.address"-->
        <!--                    :value="item">-->
        <!--                </el-option>-->
        <!--              </el-select>-->
        <!--              <el-button type="primary" @click="addMapClick(2)">标注目的地</el-button>-->
        <!--            </el-row>-->
        <!--          </el-col>-->
        <!--          <el-col :span="6">-->
        <!--            <el-row>-->
        <!--              <el-button type="primary" @click="removeMapClick">起始点标注完成</el-button>-->
        <!--            </el-row>-->
        <!--            <el-row>-->
        <!--              <el-button type="primary" @click="routePlan">路径规划</el-button>-->
        <!--            </el-row>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      CommonUrl: window.CommonUrl,
      polyLines: [],
      path: [],
      // gis 地图
      // map: null,
      zoom: 16,
      control: null,
      handler: null,
      isPaint: false,
      localsearch: null, // 位置搜索对象
      startLocalKey: '',
      endLocalKey: '',
      startSearchArr: [],
      endSearchArr: [],
      startOrEndLocalSearch: '',
      loading: false,
      startIcon: "http://lbs.tianditu.gov.cn/images/bus/start.png",	//起点图标
      endIcon: "http://lbs.tianditu.gov.cn/images/bus/end.png",		//终点图标
      actionMarker: 0, // 标注操作 0-普通标注 1-起点 2-终点
      drivingRoute: null,
      startLngLat: '', // 起点
      startLngLatArr: [], // 起点数组
      endLngLat: '', // 终点
      lineColors: ["#2dcbf3", "#dd3efa", "#27ea46", "#f6351b"],
      currentColor: -1,
      markerTool: null, // 标注工具对象
      handlerMarkerTool: null,
      carTrackArr: [],
      carTrack: null, // 驾车轨迹
      map_T: null, //
      zuobiao: [], //
      geojsonStr: '', //
      geojsonArr: [], //
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      console.log(window.T, 'window.T=')
      this.map_T = window.T;
      this.map = new this.map_T.Map('mapContainer', {
        projection: 'EPSG:4326'
      })
      // 设置显示地图的中心点和级别
      this.map.centerAndZoom(new this.map_T.LngLat(102.706108, 25.043333), this.zoom)

      //创建\添加缩放平移控件, 设置控件位置
      this.control = new this.map_T.Control.Zoom()
      this.map.addControl(this.control)
      //创建\添加比例尺控件
      let scale = new this.map_T.Control.Scale()
      this.map.addControl(scale)
      // 标绘控件
      // let military = new this.map_T.Control.militarySymbols()
      // this.map.addControl(military)
      // 添加地图类型切换控件
      let ctrl = new this.map_T.Control.MapType()
      this.map.addControl(ctrl)
      // 画笔工具
      this.isPaint = false
      this.handler = new this.map_T.PaintBrushTool(this.map, {
        keepdrawing: true,
        style: {
          weight: 3
        }
      })
      // 路径规划
      let configRoute = {
        policy: 0,	//驾车策略
        onSearchComplete: this.searchRouteResult	//检索完成后的回调函数
      }
      this.drivingRoute = new this.map_T.DrivingRoute(this.map, configRoute)
      // 位置搜索
      let configSearch = {
        pageCapacity: 10,	//每页显示的数量
        onSearchComplete: this.localSearchResult	//接收数据的回调函数
      }
      this.localsearch = new this.map_T.LocalSearch(this.map, configSearch)
      // 创建标注工具对象
      this.markerTool = new this.map_T.MarkTool(this.map, {follow: true})
      //创建标注工具对象
      let config = {
        showLabel: true,
        color: "blue", weight: 3, opacity: 0.5, fillColor: "#FFFFFF", fillOpacity: 0.5
      }
      new this.map_T.PolygonTool(this.map, config);
    },
    // 清空地图
    clearMap(flag) {
      if (flag) {
        this.startLocalKey = ''
        this.endLocalKey = ''
        this.startSearchArr = []
        this.endSearchArr = []
        this.startLngLatArr = []
        this.zuobiao = []
        this.geojsonArr = []
      }
      this.map.clearOverLays()
      this.carTrackArr.forEach((item) => {
        item.clear()
      })
    },
    // 平移
    moveTo(lngLat) {
      this.map.panTo(lngLat)
    },
    // 画笔功能开启关闭
    handlerPaint() {
      this.isPaint = !this.isPaint
      if (this.isPaint) {
        this.handler.open()
      } else {
        this.handler.close()
      }
    },
    // 添加标注 0-普通标注 1-起点 2-终点
    addLocalMarker(obj, LngLat, flag) {
      console.log(obj, LngLat, flag, 'addLocalMarker================')
      this.endeditMarker()
      let marker = ''
      if (flag === 0) {
        marker = new this.map_T.Marker(LngLat)
        this.dragMarker()
      } else {
        const iconImg = {
          icon: new this.map_T.Icon({
            iconUrl: flag == 1 ? this.startIcon : this.endIcon,
            iconSize: new this.map_T.Point(44, 34),
            iconAnchor: new this.map_T.Point(12, 31)
          })
        }
        //创建标注对象
        marker = new this.map_T.Marker(LngLat, iconImg)
      }
      //向地图上添加标注
      this.map.addOverLay(marker)
      // 注册标注点的点击事件
      this.dialogInfo(obj, marker)
    },
    // 拖动标注
    dragMarker() {
      let markers = this.markerTool.getMarkers()
      for (let i = 0; i < markers.length; i++) {
        markers[i].enableDragging()
      }
    },
    // 禁止拖动标注
    endeditMarker() {
      let markers = this.markerTool.getMarkers()
      for (let i = 0; i < markers.length; i++) {
        markers[i].disableDragging()
      }
    },
    // 地图点击事件
    addMapClick(flag) {
      this.actionMarker = flag
      this.removeMapClick()
      this.map.addEventListener("click", this.MapClick)
    },
    removeMapClick() {
      this.map.removeEventListener("click", this.MapClick)
    },
    MapClick(e) {
      console.log(e, 'MapClick================')
      this.addLocalMarker(e, new this.map_T.LngLat(e.lnglat.getLng(), e.lnglat.getLat()), this.actionMarker)
      switch (this.actionMarker) {
        case 0:
          this.startLngLat = ''
          this.endLngLat = ''
          break;
        case 1:
          this.startLngLat = new this.map_T.LngLat(e.lnglat.getLng(), e.lnglat.getLat())
          this.startLngLatArr.push(this.startLngLat)
          // this.removeMapClick()
          break;
        case 2:
          this.endLngLat = new this.map_T.LngLat(e.lnglat.getLng(), e.lnglat.getLat())
          this.removeMapClick()
          break;
      }
    },
    // 路径规划
    routePlan() {
      this.removeMapClick()
      // this.clearMap(false)
      if (this.startLngLatArr.length > 1) {
        this.moveTo(this.endLngLat)
      }
      this.startLngLatArr.forEach((item) => {
        for (let i = 0; i < 3; i++) {
          // 设置策略
          this.drivingRoute.setPolicy(i)
          // 驾车路线搜索
          this.drivingRoute.search(item, this.endLngLat)
        }
      })
    },
    searchRouteResult(result) {
      this.currentColor = (this.currentColor < 3 ? this.currentColor + 1 : 0)
      // 获取方案个数
      const routes = result.getNumPlans()
      for (let i = 0; i < routes; i++) {
        // 获得单条驾车方案结果对象
        let plan = result.getPlan(i)
        //直接显示驾车线路
        // this.createRoute(plan.getPath(), this.getRandomColor())
        // 显示驾车移动轨迹线路
        this.createMoveRoute(plan.getPath(), this.lineColors[this.currentColor])
        if (this.startLngLatArr.length === 1) {
          // 显示最佳级别
          this.map.setViewport(plan.getPath())
        }
      }
    },
    // 创建线对象
    createRoute(lnglats, lineColor) {
      //创建线对象
      const line = new this.map_T.Polyline(lnglats, {color: lineColor, weight: 5, opacity: 0.7});
      //向地图上添加线
      this.map.addOverLay(line);
    },
    // 移动轨迹
    createMoveRoute(lnglats, lineColor) {
      this.carTrack = new this.map_T.CarTrack(this.map, {
        interval: 20,
        speed: 20,
        dynamicLine: true,
        Datas: lnglats,
        polylinestyle: {color: lineColor, width: 8, opacity: 0.7}
      })
      this.carTrack.start()
      this.carTrackArr.push(this.carTrack)
    },
    // 位置搜索==========================start===
    querySearchSatrt(localKey) {
      console.log(localKey, 'querySearchSatrt================')
      this.loading = true
      this.startOrEndLocalSearch = 1
      setTimeout(() => {
        this.localsearch.search(localKey)
      }, 50)
    },
    selectOne(item) {
      console.log(item, 'selectOne================')
      if (item == '') return
      let lnglatArr = item.lonlat.split(",")

      let lnglat = new this.map_T.LngLat(lnglatArr[0], lnglatArr[1])
      console.log(lnglat, 'lnglat================')
      if (this.startOrEndLocalSearch == 1) {
        this.startLngLat = lnglat
        this.startLngLatArr.push(this.startLngLat)
      } else if (this.startOrEndLocalSearch == 2) {
        this.endLngLat = lnglat
      }
      this.addLocalMarker(item, lnglat, this.startOrEndLocalSearch)
      //显示地图的最佳级别
      this.map.setViewport([this.startLngLat, this.endLngLat])
    },
    querySearchEnd(localKey) {
      this.loading = true
      this.startOrEndLocalSearch = 2
      setTimeout(() => {
        this.localsearch.search(localKey)
      }, 50)
    },
    localSearchResult(result) {
      if (this.startOrEndLocalSearch == 1) {
        this.startSearchArr = result.getPois()
      } else {
        this.endSearchArr = result.getPois()
      }
      this.loading = false
    },
    //注册标注点的点击事件
    dialogInfo(obj, marker) {
      let winHtml = ''
      if (obj.lnglat) {
        winHtml = "坐标:" + obj.lnglat.lng + ' ' + obj.lnglat.lat
      } else {
        winHtml = "名称:" + obj.name + "<br/>地址:" + obj.address + "<br/>电话:" + (obj.phone ? obj.phone : '') + "<br/>坐标:" + (obj.lonlat ? obj.lonlat : '')
      }
      console.log('winHtml=========', winHtml)
      const that = this
      marker.addEventListener("click", function () {
        that.showPosition(marker, winHtml)
      })
    },
    //显示信息框
    showPosition(marker, winHtml) {
      let markerInfoWin = new this.map_T.InfoWindow(winHtml, {autoPan: true})
      marker.openInfoWindow(markerInfoWin)
    },
    // 位置搜索==========================end===
    // 使用标注工具
    openPolygonTool() {
      if (this.geojsonArr.length > 0) {
        this.$message({
          message: '只能绘制一个网格！',
          type: 'warning'
        });
        return false;
      }
      if (this.handlerMarkerTool) this.handlerMarkerTool.close()
      this.handlerMarkerTool = new this.map_T.PolygonTool(this.map)

      setTimeout(() => {
        this.handlerMarkerTool.open()
      }, 1000)

      this.handlerMarkerTool.addEventListener('draw', this.polygonToolDoubleClick);
    },
    openPolylineTool() {
      if (this.handlerMarkerTool) this.handlerMarkerTool.close()
      this.handlerMarkerTool = new this.map_T.PolylineTool(this.map)
      this.handlerMarkerTool.open()
    },
    openRectangleTool() {
      if (this.handlerMarkerTool) this.handlerMarkerTool.close()
      this.handlerMarkerTool = new this.map_T.RectangleTool(this.map)
      this.handlerMarkerTool.open()
    },
    openCircleTool() {
      if (this.handlerMarkerTool) this.handlerMarkerTool.close()
      this.handlerMarkerTool = new this.map_T.CircleTool(this.map)
      this.handlerMarkerTool.open()
    },
    getOverlays() {
      alert(this.map.getOverlays().length)
    },
    convertToGeoJSON() {
      // 创建一个 GeoJSON 对象
      const geojson = {
        type: 'FeatureCollection',
        features: [{
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Polygon', // 这里假设你要绘制的是多边形，如果是折线，则改为 'LineString'
            coordinates: [
              [
                [...this.zuobiao.map(coord => [coord.lng, coord.lat]), [this.zuobiao[0]
                    .lng, this.zuobiao[0].lat
                ]]
              ]
            ]
          }
        }]
      };
      // 转换为字符串
      // this.geojsonStr = JSON.stringify(geojson);
      this.geojsonArr.push(geojson)
      // 输出 GeoJSON 字符串
      console.log(this.geojsonArr);

      // 这里可以根据需要将 geojsonStr 存储到文件或发送给后端处理
    },
    polygonToolDoubleClick(e) {
      console.log("顶点坐标列表 ----------> ", e.currentLnglats);
      this.zuobiao = e.currentLnglats;
      this.convertToGeoJSON();
      this.handlerMarkerTool.close()
      this.handlerMarkerTool.removeEventListener('draw', this.polygonToolDoubleClick)
    },
    getGeoJSON() {
      return JSON.stringify(this.geojsonArr);
    },
    loadGeoJSON(geojsonData) {
      // 移除其他级别的数据
      this.map.clearOverLays();
      if (!geojsonData) {
        return false;
      }
      console.log(geojsonData, 'geojsonData==========')

      // 添加当前级别的 GeoJSON 数据到地图上
      // 例如：遍历数据，创建对应的覆盖物添加到地图上
      // const features = geojsonData.features;
      // for (const feature of features) {
      //     // 创建覆盖物并添加到地图
      //     // 例如：this.addOverlay(feature);
      // }
      const tsconfigs = JSON.parse(geojsonData);
      this.geojsonArr = tsconfigs;
      // const tsconfig = JSON.parse(geojsonData);
      // console.log(tsconfigs, 'tsconfigs==========')
      for (const tsconfig of tsconfigs) {

        console.log(tsconfig.features[0], 'tsconfig.features[0]')
        const bdary = tsconfig.features[0].geometry.coordinates[0][0];
        const pointArrays = [];
        for (let i = 0; i < bdary.length; i++) {
          const xyArr = bdary[i][0];
          const ptAr = bdary[i][1];
          pointArrays.push(new this.map_T.LngLat(xyArr, ptAr));
        }
        const border = new this.map_T.Polygon(pointArrays, {
          color: "blue", weight: 3, opacity: 0.5, fillColor: "#FFFFFF", fillOpacity: 0.5
        });
        this.map.addOverLay(border);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.command-center {
  width: 100%;
  height: 100%;

  .optionDiv {
    position: absolute;
    top: 50px;
    left: 50px;
    width: 80%;
    z-index: 999;
  }

  .mapContainer {
    width: 100%;
    height: 720px;
  }
}
</style>
