<template>
  <div class="editList">
    <div style="background: #fff;padding: 30px;" class="form-w-320">
      <el-form ref="form" :model="form" :rules="rules" label-width="115px">
        <titleDom title="组织机构"/>
        <div class="itemBox">
          <el-form-item label="组织机构名称" prop="name">
            <el-input v-model="form.name" placeholder="请填写组织机构名称" style="width: 320px"></el-input>
          </el-form-item>
          <el-form-item label="组织机构类型" prop="type">
            <el-radio-group v-model="form.type" @change="changeStatus">
              <el-radio v-for="item in dictData.type" :label="item.id" :key="item.id">{{ item.value }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="所属上级" prop="pid" v-if="form.type === 2 || form.type === 3">
            <treeselect
                placeholder="请选择所属上级" style="width: 320px"
                v-model="form.pid"
                :options="deptQuery.deptData"
                :normalizer="normalizer"
                :show-count="true"
            />
          </el-form-item>
          <el-form-item label="所在地区" prop="addressData" v-if="form.type === 1 || form.type === 2 || form.type === 3">
            <el-cascader
                placeholder="请选择组织机构所在地区" style="width: 320px"
                v-model="form.addressData"
                :options="dictData.addressData"
                :props="{ label: 'name', value: 'id', checkStrictly: true}"
                ref="cascader"
                @change="handleChange"></el-cascader>
          </el-form-item>
          <el-form-item label="详细地址" prop="address" v-if="form.type === 1 || form.type === 2 || form.type === 3">
            <el-input v-model="form.address" placeholder="请填写详细地址" style="width: 320px"></el-input>
          </el-form-item>
          <el-form-item label="组织机构联系人" prop="contacts" v-if="form.type === 1 || form.type === 2 || form.type === 3">
            <el-input v-model="form.contacts" placeholder="请填写组织机构联系人" style="width: 320px"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="mobile" v-if="form.type === 1 || form.type === 2 || form.type === 3">
            <el-input v-model="form.mobile" placeholder="请填写联系电话" style="width: 320px"></el-input>
          </el-form-item>
          <el-form-item label="网格属性" prop="grid_attr" v-if="form.type === 3">
            <el-radio-group v-model="form.grid_attr" @change="changeStatus">
              <el-radio v-for="item in dictData.grid_attr" :label="item.id" :key="item.id">{{ item.value }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否驻地网" prop="cpn" v-if="form.type === 3">
            <el-radio-group v-model="form.cpn" @change="changeStatus">
              <el-radio v-for="item in dictData.cpn" :label="item.id" :key="item.id">{{ item.value }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="地图边界" prop="status" v-if="form.type === 3">
            请在地图上将网格的行政区域边界进行描绘
            <tdt-map ref="tMap" />
          </el-form-item>
        </div>

        <el-form-item>
          <div style="background: #fff;" class="btn_box">
            <a class="blue" href="#" @click="submit">确定</a>
            <a class="" href="#" @click="returnToPage">返回</a>
          </div>
        </el-form-item>
      </el-form>


    </div>


  </div>
</template>

<script>

import titleDom from "@/components/common/titleDom.vue";
import TdtMap from "@/components/common/TdtMap.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

const defaultForm = {
  id: null,
  name: '',
  type: 1,
  pid: null,
  province_id: null,
  county_id: null,
  city_id: null,
  town_id: null,
  province: '',
  city: '',
  county: '',
  town: '',
  address: '',
  contacts: '',
  mobile: '',
  grid_attr: null,
  cpn: null,
  boundary: '',
  addressData: []
}
export default {
  name: "editList",
  components: {titleDom, TdtMap, Treeselect},
  data() {
    return {
      queryID: null,
      page: null,
      // 选项字典data
      dictData: {
        // 类型：0=全部 1=省级 2=州市级 3=网格
        type: [
          {
            id: 1,
            value: '省级'
          },
          {
            id: 2,
            value: '州市级'
          },
          {
            id: 3,
            value: '网格'
          }
        ],
        // 网格属性：0=全部 1=园区 2=楼宇 3=行业
        grid_attr: [
          {
            id: 1,
            value: '园区'
          },
          {
            id: 2,
            value: '楼宇'
          },
          {
            id: 3,
            value: '行业'
          }
        ],
        // 是否驻地网：0=全部 1=是 2=否
        cpn: [
          {
            id: 1,
            value: '是'
          },
          {
            id: 2,
            value: '否'
          }
        ],
        // 等级：1=省 2=市 3=区 4=镇
        level: [
          {
            id: 1,
            value: '省'
          },
          {
            id: 2,
            value: '市'
          },
          {
            id: 3,
            value: '市'
          },
          {
            id: 4,
            value: '镇'
          }
        ],
        // 地区
        addressData: []
      },
      form: {
        ...defaultForm,
      },
      rules: {
        name: [
          {required: true, message: '请输入组织机构名称', trigger: 'blur'},
        ],
        type: [
          {required: true, message: '请选择组织机构类型', trigger: 'blur'},
        ],
        pid: [
          {required: true, message: '请选择所属上级', trigger: 'blur'},
        ],
        addressData: [
          {required: true, message: '请选择所在地区', trigger: 'blur'},
        ],
        address: [
          {required: true, message: '请输入详细地址', trigger: 'blur'},
        ],
        contacts: [
          {required: true, message: '请输入组织机构联系人', trigger: 'blur'},
        ],
        mobile: [
          {required: true, message: '请输入联系电话', trigger: 'blur'},
          {pattern: /^1[3-9]\d{9}$/, message: "请输入正确的联系电话", trigger: "blur"}
        ],
        grid_attr: [
          {required: true, message: '请选择网格属性', trigger: 'blur'},
        ],
        cpn: [
          {required: true, message: '请选择是否驻地网', trigger: 'blur'},
        ],
        boundary: [
          // {required: true, message: '请设置地图边界', trigger: 'blur'},
        ]
      },
      deptQuery: {
        deptSearch: '',
        deptData: [],
        defaultProps: {
          children: "children",
          label: "name"
        },
      }
    }
  },
  mounted() {
    this.queryID = this.$route.query.id;
    this.page = this.$route.query.page;
    if (this.queryID) {
      this.getOrganize(this.queryID);
    }
    //地区
    this.$api.area_tree_list().then(res=>{
      this.$set(this.dictData, 'addressData', res.data)
    })
    this.getDeptTree()
  },
  watch: {
    'form.type': function (val) {
      this.getDeptTree(+val - 1);
      if (!this.form.id) {
        if (val === 3) {
          this.form.grid_attr = 1;
          this.form.cpn = 2;
        } else {
          this.form.grid_attr = null;
          this.form.cpn = null;
        }
      }
      this.$refs['form'].clearValidate()
    }
  },
  methods: {
    /** 查询部门下拉树结构 */
    getDeptTree(type = 1) {
      // this.$api.getOrganizeTreeList().then(res => {
      //   this.$set(this.deptQuery, 'deptData', res.data)
      // })
      this.$api.getOrganizeOption(type).then(res => {
        this.$set(this.deptQuery, 'deptData', res.data)
      })
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children
      };
    },
    getOrganize(id) {
      this.$api.getOrganize(id).then(res => {
        this.form = res.data;
        const addressData = []
        this.form.province_id && addressData.push(this.form.province_id)
        this.form.city_id && addressData.push(this.form.city_id)
        this.form.county_id && addressData.push(this.form.county_id)
        this.$set(this.form, 'addressData', addressData);
        this.$nextTick(() => {
          this.$refs['tMap'].loadGeoJSON(this.form.boundary)
        })
      })
    },
    changeStatus(val) {
      console.log(val, 111)
    },
    handleChange(value) { //选择地区之后获取网格-
      console.log(value, 222)
      this.form.province_id = value[0];
      this.form.city_id = value[1];
      this.form.county_id = value[2];
      console.log(this.form, 333)

      const checkedNodes = this.$refs.cascader.getCheckedNodes();
      const labels = checkedNodes.map(node => node.pathLabels);
      this.form.province = labels[0][0];
      this.form.city = labels[0][1];
      this.form.county = labels[0][2];
    },
    // 表单重置
    reset() {
      if (this.$refs.menu != undefined) {
        this.$refs.menu.setCheckedKeys([]);
      }
      this.menuExpand = false,
          this.menuNodeAll = false,

          this.form = {
            ...defaultForm,
            id: this.queryID
          };
      this.resetForm("form");
    },
    submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.type === 3) {
            this.form.boundary = this.$refs['tMap'].getGeoJSON();
          }

          console.log(this.form, 'form=======')

          this.$api.editOrganize(this.form).then(() => {
            this.$message({
              message: this.form.id ? "修改成功" : "新增成功",
              type: 'success'
            });
            this.reset();
            this.$router.push({name: "Organization", params: {page: this.page}});
          });
        }
      });
    },
    returnToPage() {
      this.$router.push({name: "Organization", params: {page: this.page}});
    }
  },
}
</script>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">
.editList {
  margin: 18px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  width: calc(100% - 36px);
  height: calc(100% - 36px);
  overflow: scroll;
  scrollbar-width: none;
  background: #fff;

  .itemBox {
    margin-top: 28px;
  }

  ::v-deep .vue-treeselect {
    height: 34px;
    line-height: 34px;
    background: #FFFFFF;
    border-radius: 4px;

    .vue-treeselect {
      border: 1px solid #CEDAE0;
    }
  }
}
</style>